import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API} from '@configs/api.config';
import {Observable} from 'rxjs';
import {EmptyContainerViewModel} from '../models/EmptyContainerViewModel';
import {GatewayLocation} from '@shared/models/gateway-location.model';
import {LocationType} from '@shared/models';
import { LocationCountryDetails } from '../models/org-country-details.model';
import { OrderIdsVehicleType } from '../models/orderIds-vehicleType.model';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private httpClient: HttpClient) { }

  getLocationsByLocationIdAnType(locations: Array<{locationId: string, locationType: LocationType}>): Observable<GatewayLocation[]> {
    const url = API.gateway.getLocations;
    return this.httpClient.post<GatewayLocation[]>(url, locations);
  }

  getLocationsByType(locationType: LocationType): Observable<GatewayLocation[]> {
    const url = API.gateway.getLocationsByType.format([locationType]);
    return this.httpClient.get<GatewayLocation[]>(url);
  }

  getCountriesAndStates(): Observable<LocationCountryDetails[]> {
    const url = API.organization.getCountries;
    return this.httpClient.get<LocationCountryDetails[]>(url);
  }
  getVehicleAccessibilityByOrderIds(orgId: string, jobRefId: string, data: OrderIdsVehicleType) {
    const url = API.jobFile.getVehicleAccessibilityByOrderIds.format([orgId, jobRefId]);
    return this.httpClient.post<{message: string}>(url, data);
  }
}
